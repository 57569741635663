<template>
  <div class="RoomOffer__wrapper">
    <div class="RoomOffer__title-wrapper">
      <LandingIconVideoFree />
      <h3 class="RoomOffer__title">Free Video Room</h3>
      <span class="RoomOffer__free-badge">Virtual space just for you</span>
    </div>
    <div class="RoomOffer__features-wrapper">
      <h5 class="RoomOffer__features-title">Includes:</h5>
      <ul class="RoomOffer__features-list mb-16">
        <li class="RoomOffer__features-list-element">
          <o-icon icon="circle-small" />Up to 10 participants
        </li>
        <li class="RoomOffer__features-list-element">
          <o-icon icon="circle-small" />
          Meetings up to 50 minutes per session
        </li>
      </ul>
      <h5 class="RoomOffer__features-title">Key features:</h5>
      <ul class="RoomOffer__features-list">
        <li class="RoomOffer__features-list-element">
          <o-icon custom-size="mdi-20px" icon="creation" />
          Free and easy to manage virtual space
        </li>
        <li class="RoomOffer__features-list-element">
          <o-icon custom-size="mdi-20px" icon="calendar" />
          Schedule meetings
        </li>
        <li class="RoomOffer__features-list-element">
          <o-icon custom-size="mdi-20px" icon="forum" />
          Dynamic in-meeting chat & reactions
        </li>
        <li class="RoomOffer__features-list-element">
          <o-icon custom-size="mdi-20px" icon="cog" />
          Manage participants easily
        </li>
        <li class="RoomOffer__features-list-element">
          <LandingIconShare />
          Screen Sharing
        </li>
      </ul>
    </div>
    <div class="RoomOffer__price-wrapper">
      <h6 class="RoomOffer__price-text">{{ currencySymbol }}0</h6>
      <p class="RoomOffer__price-description">
        {{
          subscriptionType === "monthly"
            ? "Per host/monthly"
            : "Per host/annually"
        }}
      </p>
      <NuxtLink
        to="/dashboard"
        :external="true"
        class="RoomOffer__price-button"
      >
        Get started for free <o-icon icon="arrow-right" />
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCurrencyStore } from "~/store/currency";
import { CURRENCY_SETTINGS } from "~/types";
type Props = {
  subscriptionType: "monthly" | "annual";
};
const props = defineProps<Props>();

const currencyStore = useCurrencyStore();
const currencySymbol = computed(() => {
  return CURRENCY_SETTINGS[currencyStore.defaultCurrency.currency.code].symbol;
});
</script>
<style scoped lang="scss">
.RoomOffer {
  &__wrapper {
    display: flex;
    flex-direction: column;
    background: $color-basic-white;
    border-radius: 20px;
    padding: 32px 24px 24px 24px;
    height: 100%;
  }
  &__title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
  }
  &__title {
    @include Header-24;
    color: $color-element-dark-strong;
    margin-top: 8px;
    margin-bottom: 32px;
  }
  &__free-badge {
    @include Label-16;
    padding: 7px 15px;
    border-radius: 51px;
    width: 224px;
    color: $color-basic-success-500;
    background: $color-basic-success-100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__features-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    border-top: 2px solid $color-neutral-100;
    border-bottom: 2px solid $color-neutral-100;
    flex: 1;
  }
  &__features-title {
    @include Body-16;
    font-weight: 600;
    color: $color-element-dark-strong;
    margin-bottom: 8px;
  }
  &__features-list-element {
    @include Body-16;
    color: $color-element-dark-medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    .o-icon {
      color: $color-element-dark-disabled;
    }
  }
  &__features-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    &.mb-16 {
      margin-bottom: 16px;
    }
  }
  &__price-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 12px;
    background: $color-basic-success-100;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  &__price-text {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.65px;
    color: $color-basic-success-500;
    margin: 0;
  }
  &__price-description {
    @include Body-16;
    color: $color-element-dark-medium;
  }
  &__price-button {
    @include button-primary;
    background: $color-basic-success-500;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    &.disabled {
      display: none;
      pointer-events: none;
      background: $color-neutral-200;
      color: $color-element-dark-disabled;
    }
    &:hover {
      .o-icon {
        animation: button-arrow-move 0.4s ease-in alternate infinite;
      }
    }
  }
}
</style>

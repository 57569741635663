<template>
  <section id="plans" aria-label="Pricing" class="Plans__wrapper">
    <div class="Plans__header-wrapper">
      <LandingBadge icon="circle-multiple-outline">Pricing</LandingBadge>
      <h2 class="Plans__heading">Flexible Plans for Every Business Size</h2>
      <p class="Plans__paragraph">
        Invest in online conferencing that drives your business. Clear,
        straightforward plans tailored to&nbsp;your needs.
      </p>
    </div>
    <div class="Plans__list-wrapper">
      <div class="Plans__content-wrapper">
        <div class="Plans__billing-types-wrapper">
          <button
            class="Plans__billing-type-button"
            :class="{ active: subscriptionType === 'monthly' }"
            @click="handleChangeToMonthlyBilling"
          >
            Billed monthly
          </button>
          <span class="Plans__billing-divider"></span>
          <button
            class="Plans__billing-type-button"
            :class="{ active: subscriptionType === 'annual' }"
            @click="handleChangeToAnnuallyBilling"
          >
            Billed annually<span>-10%</span>
          </button>
        </div>
        <ul v-if="offerTypes.data.value" class="Plans__list">
          <li class="Plans__list-element">
            <LandingRoomOfferFree :subscription-type="subscriptionType" />
          </li>
          <li
            v-for="(offerType, index) in offers"
            :key="index"
            class="Plans__list-element"
          >
            <LandingRoomOffer
              :offers="offerType"
              :subscription-type="subscriptionType"
              @change-to-monthly-billing="handleChangeToMonthlyBilling"
            />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { OfferType, OffersResponse } from "~/types";

const runtimeConfig = useRuntimeConfig();

const offerTypes = await useAsyncData<OffersResponse>("offerTypes", () =>
  $fetch(`${runtimeConfig.public.backendApiUrl}/rooms/offers`),
);

const subscriptionType = ref<"monthly" | "annual">("monthly");

const offers = computed(() => {
  if (!offerTypes.data.value) {
    return {};
  }
  return offerTypes.data.value.offers_summaries.reduce(
    (acc, currentValue) => {
      const groupKey = currentValue.room_type;
      if (!(groupKey in acc)) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    },
    {} as Record<string, OfferType[]>,
  );
});

const handleChangeToMonthlyBilling = () => {
  subscriptionType.value = "monthly";
};

const handleChangeToAnnuallyBilling = () => {
  subscriptionType.value = "annual";
};
</script>
<style scoped lang="scss">
.Plans {
  &__wrapper {
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__header-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    padding: 0 16px 0 16px;
    @include md {
      align-items: center;
      max-width: 724px;
    }
  }
  &__content-wrapper {
    @include md {
      max-width: 1440px;
      width: 100%;
      margin: auto;
    }
  }
  &__heading {
    @include Header-40;
    color: $color-neutral-950;
    margin-bottom: 20px;
    margin-top: 16px;
    @include md {
      @include Header-54;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
  &__paragraph {
    @include Body-16;
    color: $color-neutral-800;
    @include md {
      text-align: center;
    }
  }
  &__list-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: $color-neutral-100;
    padding: 0 16px 80px 16px;
    width: 100%;
  }
  &__billing-types-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 60px 0 40px 0;
    column-gap: 16px;
    @include md {
      justify-content: flex-end;
    }
  }
  &__billing-divider {
    width: 2px;
    background: $color-neutral-800;
  }
  &__billing-type-button {
    @include Label-16;
    color: $brand-primary-500;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    &.active {
      color: $color-neutral-800;
    }
    span {
      @include Label-12;
      padding: 6px 10px;
      border-radius: 999px;
      background: $color-basic-white;
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    @include custom-min-width(1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 40px;
    }
  }
  &__list-element {
  }
}
</style>

<template>
  <div class="RoomOffer__wrapper">
    <div class="RoomOffer__title-wrapper">
      <component :is="iconComponent" v-bind="{ expired: false }" />
      <h3 class="RoomOffer__title">{{ offerType?.name }}</h3>
      <div class="RoomOffer__type-wrapper">
        <span
          class="RoomOffer__type-background"
          :class="{ active: roomType === 'big' }"
        ></span>
        <button
          class="RoomOffer__type-button"
          :class="{ active: roomType === 'standard' }"
          @click="handleRoomTypeChange('standard')"
        >
          Standard room
        </button>
        <button
          class="RoomOffer__type-button"
          :disabled="subscriptionType === 'annual'"
          :class="{ active: roomType === 'big' }"
          @click="handleRoomTypeChange('big')"
        >
          Big room
        </button>
      </div>
    </div>
    <div class="RoomOffer__features-wrapper">
      <h5 class="RoomOffer__features-title">Includes:</h5>
      <ul class="RoomOffer__features-list mb-16">
        <li class="RoomOffer__features-list-element">
          <o-icon icon="circle-small" />Up to
          {{ offerType?.max_participants }} participants
        </li>
        <li class="RoomOffer__features-list-element">
          <o-icon icon="circle-small" />
          {{ featureDurationText }}
        </li>
      </ul>
      <h5 class="RoomOffer__features-title">Key features:</h5>
      <ul class="RoomOffer__features-list">
        <li class="RoomOffer__features-list-element">
          <LandingIconDataConsumption />
          Low data consumption
        </li>
        <li class="RoomOffer__features-list-element">
          <o-icon custom-size="mdi-20px" icon="calendar" />
          Schedule meetings
        </li>
        <li class="RoomOffer__features-list-element">
          <o-icon custom-size="mdi-20px" icon="forum" />
          Dynamic in-meeting chat & reactions
        </li>
        <li class="RoomOffer__features-list-element">
          <o-icon custom-size="mdi-20px" icon="cog" />
          Manage participants easily
        </li>
        <li
          v-if="offerType?.room_type !== 'audio'"
          class="RoomOffer__features-list-element"
        >
          <LandingIconShare />
          Screen Sharing
        </li>
      </ul>
    </div>
    <div class="RoomOffer__price-wrapper" :class="roomTypeClass">
      <h6 class="RoomOffer__price-text" :class="roomTypeClass">
        {{ price?.beforeDiscount }}
      </h6>
      <p class="RoomOffer__price-description">
        {{
          subscriptionType === "monthly"
            ? "Per host/monthly"
            : "Per host/annually"
        }}
      </p>
      <NuxtLink
        :to="offerLink"
        class="RoomOffer__price-button"
        :external="true"
        :class="roomTypeClass"
      >
        Get started <o-icon icon="arrow-right" />
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCurrencyStore } from "~/store/currency";
import { CURRENCY_SETTINGS, OfferType } from "~/types";
type Props = {
  offers: OfferType[];
  subscriptionType: "monthly" | "annual";
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "change-to-monthly-billing"): void;
}>();

const currencyStore = useCurrencyStore();
const roomType = ref<"standard" | "big">("standard");

const handleRoomTypeChange = (type: "standard" | "big") => {
  roomType.value = type;
  if (type === "big") {
    emit("change-to-monthly-billing");
  }
};

const iconAudio = resolveComponent("DashboardIconAudio");
const iconVideo = resolveComponent("DashboardIconVideo");
const iconStreamed = resolveComponent("DashboardIconStreamed");

const offerType = computed(() => {
  return props.offers.find((item) => {
    if (roomType.value === "big") {
      return item.offer_code.includes("BIG");
    }
    return !item.offer_code.includes("BIG");
  });
});

const offerWithChosenCurrency = computed(() => {
  if (!offerType.value) {
    return;
  }

  return offerType.value.offers
    .filter((el) => {
      return el.subscription_type.includes(props.subscriptionType);
    })
    .find((el) => {
      return el.currency === currencyStore.defaultCurrency.currency.code;
    });
});

const featureDurationText = computed(() => {
  return `Unlimited meetings, up to ${maxDuration.value} per meeting`;
});

const iconComponent = computed(() => {
  if (!offerType.value) {
    return;
  }
  switch (offerType.value.room_type) {
    case "audio":
      return iconAudio;
    case "video":
      return iconVideo;
    case "streamed":
      return iconStreamed;
  }
});

const roomTypeClass = computed(() => {
  if (!offerType.value) {
    return;
  }
  switch (offerType.value.room_type) {
    case "audio":
      return "tertiary";
    case "video":
      return "primary";
  }
});

const offerLink = computed(() => {
  if (!offerWithChosenCurrency.value) {
    return "";
  }
  return `/dashboard?offerTypeId=${offerType.value?.offer_type_id}`;
});

const price = computed(() => {
  if (!offerWithChosenCurrency.value) {
    return;
  }

  return {
    current: `${CURRENCY_SETTINGS[offerWithChosenCurrency.value?.currency].symbol}${(
      offerWithChosenCurrency.value.price / 100
    ).toFixed(2)}`,
    beforeDiscount: `${CURRENCY_SETTINGS[offerWithChosenCurrency.value?.currency].symbol}${(
      offerWithChosenCurrency.value.price_before_discount / 100
    ).toFixed(2)}`,
  };
});

const maxDuration = computed(() => {
  if (!offerType.value) {
    return;
  }
  const duration = offerType.value.max_duration_in_sec / 60 / 60;
  if (duration < 1) {
    return `${offerType.value.max_duration_in_sec / 60} min`;
  }
  return `${duration}h`;
});

watch(
  () => props.subscriptionType,
  (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }
    if (newValue === "annual") {
      roomType.value = "standard";
    }
  },
);
</script>
<style scoped lang="scss">
.RoomOffer {
  &__wrapper {
    display: flex;
    flex-direction: column;
    background: $color-basic-white;
    border-radius: 20px;
    height: 100%;
    padding: 32px 24px 24px 24px;
  }
  &__title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
    svg {
      width: 52px;
      height: 52px;
    }
  }
  &__title {
    @include Header-24;
    color: $color-element-dark-strong;
    margin-top: 8px;
    margin-bottom: 32px;
  }
  &__type-wrapper {
    display: flex;
    flex-direction: row;
    width: 290px;
    background: $color-neutral-100;
    border-radius: 999px;
    padding: 2px;
    position: relative;
  }
  &__type-background {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 50%;
    height: calc(100% - 4px);
    background: $color-neutral-950;
    border-radius: 999px;
    transition: transform 0.4s ease-in-out;
    &.active {
      transform: translateX(calc(100% - 4px));
    }
  }
  &__type-button {
    @include Label-16;
    padding: 7px 15px;
    border-radius: 999px;
    border: none;
    background: none;
    color: $color-neutral-800;
    cursor: pointer;
    width: 100%;
    z-index: 1;
    transition: color 0.4s ease-in;
    &.active {
      color: $color-element-light-strong;
    }
    &:disabled {
      color: $color-element-dark-disabled;
      pointer-events: none;
    }
  }
  &__features-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    border-top: 2px solid $color-neutral-100;
    border-bottom: 2px solid $color-neutral-100;
    flex: 1;
  }
  &__features-title {
    @include Body-16;
    font-weight: 600;
    color: $color-element-dark-strong;
    margin-bottom: 8px;
  }
  &__features-list-element {
    @include Body-16;
    color: $color-element-dark-medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    .o-icon {
      color: $color-element-dark-disabled;
    }
  }
  &__features-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    &.mb-16 {
      margin-bottom: 16px;
    }
  }
  &__price-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 12px;
    background: $color-basic-success-100;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    &.primary {
      background: $brand-primary-050;
    }
    &.tertiary {
      background: $brand-tertiary-050;
    }
  }
  &__price-text {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.65px;
    color: $color-basic-success-500;
    margin: 0;
    &.primary {
      color: $brand-primary-500;
    }
    &.tertiary {
      color: $brand-tertiary-500;
    }
  }
  &__price-description {
    @include Body-16;
    color: $color-element-dark-medium;
  }
  &__price-button {
    @include button-primary;
    background: $color-basic-success-500;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    &:hover {
      .o-icon {
        animation: button-arrow-move 0.4s ease-in alternate infinite;
      }
    }
    &.primary {
      background: $brand-primary-500;
    }
    &.tertiary {
      background: $brand-tertiary-500;
    }
    &.disabled {
      display: none;
      pointer-events: none;
      background: $color-neutral-200;
      color: $color-element-dark-disabled;
    }
  }
}
</style>
